import Breadcrumb from '@components/common/breadcrumb'
import { FastlyImage } from '@components/common/image'
import { CODING_FOR_KIDS_ITEMS } from '@lib/data/breadcrumb'

const IMAGE_PATH = '/images/coding-for-kids/'

const IN_PAGE_LINKS = [
  { path: '#coding-competitions', title: 'Coding Competitions' },
  { path: '#coding-quizzes', title: 'Coding Quizzes' },
  { path: '#coding-masterclasses', title: 'Coding Masterclasses' },
  { path: '#coding-worksheets', title: 'Coding Worksheets' },
  { path: '#coding-blogs', title: 'Coding Blogs' },
  { path: '#coding-guides', title: 'Coding Guides' },
  { path: '#coding-webinars', title: 'Coding Webinars' },
  { path: '#coding-courses', title: 'Coding Courses' },
]

const FirstFold = () => {
  return (
    <div style={{ backgroundColor: '#FFF7F5' }}>
      <div className="flex text-grey container mx-auto">
        <div className="leading-tight max-w-xl mx-auto px-5 py-10">
          <div className="mb-8 lg:-ml-8">
            <Breadcrumb items={CODING_FOR_KIDS_ITEMS} />
          </div>
          <h1 className="text-2xl lg:text-4xl font-700 mb-8 max-w-2xl">
            Coding for kids - 8 free coding resources to start their coding
            journey
          </h1>
          <p className="lg:text-xl font-600 mb-5">Table of contents</p>
          <ol className="font-600 text-xl flex flex-col space-y-3 pl-5">
            {IN_PAGE_LINKS.map((link) => (
              <li className="text-orange hover:underline" key={link.title}>
                <a href={link.path}>{link.title}</a>
              </li>
            ))}
          </ol>
        </div>

        <div className="hidden lg:block relative text-center">
          <div className="relative mx-10">
            <FastlyImage
              src={`${IMAGE_PATH}hero-picture-v2.png`}
              webpSrc={`${IMAGE_PATH}hero-picture-v2.webp`}
              height={550}
              width={550}
              alt="Free Online Coding For Kids"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default FirstFold
