import CodingBlogs from '@components/coding-for-kids/coding-blogs'
import CodingCourses from '@components/coding-for-kids/coding-courses'
import CodingForKidsFold from '@components/coding-for-kids/coding-for-kids'
import CodingGuides from '@components/coding-for-kids/coding-guides'
import CodingMasterClasses from '@components/coding-for-kids/coding-masterclasses'
import CodingQuizzesForKids from '@components/coding-for-kids/coding-quizzes-kids'
import CodingWebinars from '@components/coding-for-kids/coding-webinars'
import CodingWorkSheets from '@components/coding-for-kids/coding-worksheets'
import FirstFold from '@components/coding-for-kids/first-fold'
import RelatedCodingResources from '@components/coding-for-kids/related-coding-resources'
import Footer from '@components/common/footer'
import NavBar from '@components/common/navbar'
import CompetitionsFold from '@components/home/competitions-fold'
import Faq from '@components/home/faq'
import useStoreUTMParams from '@hooks/useStoreUTMParam'
import { FAQ_CONTENTS } from '@lib/data/faq'
import { codingForKidsGlobalSeo } from '@lib/lp-seo'
import { FAQPageJsonLd, NextSeo } from 'next-seo'

const CodingForKids = () => {
  useStoreUTMParams()

  return (
    <>
      <NextSeo {...codingForKidsGlobalSeo} />
      <FAQPageJsonLd
        mainEntity={FAQ_CONTENTS.map(
          ({ acceptedAnswerTextString, questionName }) => ({
            acceptedAnswerText: acceptedAnswerTextString,
            questionName: questionName,
          })
        )}
      />
      {/* <CarouselJsonLd ofType="course" data={COURSE_CAROUSEL_SCHEMA_DATA} /> */}
      <NavBar />
      <FirstFold />
      <CodingForKidsFold />
      <CompetitionsFold
        heading="Coding competitions for kids"
        subHeading="These competitions are an excellent way for kids to start learning to code in a fun context.  Our expert computer science teachers help kids build their first coding project with 1:1 free mentorship sessions."
        shouldShowCTAs={false}
        shouldShowPracticeCompetitions
      />
      <CodingQuizzesForKids />
      <CodingMasterClasses />
      <CodingWorkSheets />
      <CodingBlogs />
      <CodingGuides />
      <CodingWebinars />
      <CodingCourses />
      <div className="mb-20">
        <Faq />
      </div>
      <RelatedCodingResources />
      <Footer />
    </>
  )
}

// TODO: Remove once getInitialProps is removed from _app.tsx
export const getStaticProps = async () => {
  return {
    props: {},
  }
}

export default CodingForKids
