import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'

const IMAGE_PATH = '/images/coding-for-kids/'

const CodingWebinars = () => {
  return (
    <div
      className="flex flex-col-reverse lg:flex-row items-center lg:gap-x-10 px-5 text-grey container mx-auto mt-20"
      id="coding-webinars"
    >
      <div>
        <h2 className="text-2xl lg:text-4xl font-700">Coding webinars</h2>
        <p className="mt-4 mb-6 text-xl">
          Free online masterclass for kids to learn about animation, game
          development, application building, web development, and more.
        </p>
        <a
          href="https://www.codingal.com/coding-for-kids/webinars/"
          target="_blank"
          rel="noreferrer"
          className="w-full lg:w-auto"
        >
          <Button variant="outline" className="w-full lg:w-auto">
            Watch webinar recordings
          </Button>
        </a>
      </div>
      <div>
        <FastlyImage
          src={`${IMAGE_PATH}coding-webinars.png`}
          webpSrc={`${IMAGE_PATH}coding-webinars.webp`}
          height="300"
          width="420"
          alt="Best coding webinars for kids"
        />
      </div>
    </div>
  )
}
export default CodingWebinars
