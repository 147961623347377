import classNames from 'classnames'
import { Fragment } from 'react'

type Props = {
  items: {
    name: string
    link: string
  }[]
  isInCoursePage?: boolean
}

const Breadcrumb = ({ items, isInCoursePage }: Props) => {
  const itemsLength = items.length - 1
  const isTheLastIndex = (i: number) => i === itemsLength

  return (
    <div
      className={classNames('flex justify-start space-x-2 font-600 text-sm', {
        'text-orange md:text-white': isInCoursePage,
        'text-grey': !isInCoursePage,
      })}
    >
      {items.map((s, i) => (
        <Fragment key={s.name}>
          {!isTheLastIndex(i) && (
            <>
              <a key={s.name} href={s.link} className="hover:underline">
                {s.name}
              </a>
              <span className="inline-block">&gt;</span>
            </>
          )}
          {isTheLastIndex(i) && (
            <span
              className={classNames('inline-block', {
                'text-orange md:text-white': isInCoursePage,
                'text-grey-800': !isInCoursePage,
              })}
            >
              {items[itemsLength].name}
            </span>
          )}
        </Fragment>
      ))}
    </div>
  )
}

export default Breadcrumb
