import { FastlyImage } from '@components/common/image'
import { useState } from 'react'

const IMAGE_PATH = '/images/coding-for-kids/'

const CodingForKids = () => {
  const [shouldExpand, setShouldExpand] = useState(false)

  return (
    <div className="grid lg:grid-cols-2 gap-x-5 px-5 text-grey container mx-auto mt-10 lg:mt-20">
      <div>
        <h2 className="text-2xl lg:text-4xl font-700 mb-4">Coding for kids</h2>
        <div className="flex flex-col items-start space-y-4 text-xl">
          <div className="flex flex-col space-y-3">
            <p>
              Learning to code can give your child the foundational skills for
              future success.
            </p>
            <p>
              Like enrolling your child in soccer, piano, or art classes, coding
              builds skills that can be useful across your child&apos;s entire
              life.
            </p>
            <p>
              No matter what field your child ends up going into, a basic
              understanding of the principles of coding is imperative.
            </p>
          </div>
          {shouldExpand && (
            <>
              <p>
                Coding is about telling a computer what to do. A computer
                programmer uses code to give a set of commands that a computer
                knows how to follow.
              </p>
              <p>
                Different computers use different programming languages like C,
                C++, Java, Python, PHP, Ruby and many more.
              </p>
              <p>
                There are games focused on coding skills, such as Minecraft,{' '}
                <a
                  href="https://www.codingal.com/courses/scratch-programming/"
                  target="blank"
                  rel="noreferrer"
                  className="text-blue-200 underline"
                >
                  Scratch coding
                </a>
                , and Roblox. Online{' '}
                <a
                  href="https://www.codingal.com/"
                  target="blank"
                  rel="noreferrer"
                  className="text-blue-200 underline"
                >
                  coding class for kids
                </a>{' '}
                can help them develop valuable skills that will be used in their
                careers. It helps keep pace with technology.
              </p>
              <p>
                Coding for kids not only helps improve their logical thinking,
                critical thinking, problem-solving skills, creativity,
                mathematics and writing skills but also gives them valuable
                skills in life and at the workplace.
              </p>
              <p>
                Kids who code, also teach other kids how to code which creates
                more competition and diversity of the knowledge that children
                have with respect to computer science. Coding provides a
                competitive advantage when applying to colleges, internships,
                and jobs.
              </p>
              <p>
                Basic programming knowledge can change the way kids interact
                with the technologies they use daily. It’s a basic literacy–one
                we can’t afford to overlook.
              </p>
              <p>
                As the overlap of technology and our everyday lives increases,
                so will our interactions with technology – and so will the
                demand for tech-savvy employees.
              </p>
              <p>
                Coding for kids carries a multitude of benefits, that is the
                language of the future and a skill priceless for the future job
                market.
              </p>
              <p>
                Whether a child wants to become a doctor, lawyer, pilot, or
                engineer, learning to code is essential because technology is
                embedded in all aspects of life and will continue to do so in
                the future.
              </p>
              <p>
                Coding is the future and if we want your children to be
                competitive in the global marketplace, then coding classes at a
                young age are a must.
              </p>
            </>
          )}
          <button
            className="lg:text-xl decoration-1 font-600 underline mt-2 focus:outline-none"
            onClick={() => setShouldExpand(!shouldExpand)}
          >
            {!shouldExpand ? 'Read more' : 'Read less'}
          </button>
        </div>
      </div>
      <div className="lg:block hidden text-center">
        <FastlyImage
          src={`${IMAGE_PATH}coding-for-kids.png`}
          webpSrc={`${IMAGE_PATH}coding-for-kids.webp`}
          height="400"
          width="450"
          alt="Coding for kids - free coding quizzes, workshops, resources, blogs, coding competitions and much more."
        />
      </div>
    </div>
  )
}
export default CodingForKids
