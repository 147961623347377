import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'

const IMAGE_PATH = '/images/coding-for-kids/'

const CodingQuizzesForKids = () => {
  return (
    <div
      className="flex flex-col px-5 lg:flex-row container items-center mx-auto mt-20 lg:mt-40 lg:space-x-16 text-grey"
      id="coding-quizzes"
    >
      <div className="block relative">
        <FastlyImage
          src={`${IMAGE_PATH}coding-quizzes.png`}
          webpSrc={`${IMAGE_PATH}coding-quizzes.webp`}
          height={400}
          width={530}
          alt="Best online coding quizzes for kids"
        />
      </div>
      <div>
        <h2 className="text-2xl lg:text-4xl font-700">Coding quizzes</h2>
        <p className="text-xl leading-tight mt-4 mb-6">
          Great collection of fun and interactive quizzes for kids to assess and
          acquire computer science knowledge.
        </p>
        <a
          href="https://www.codingal.com/quizzes/"
          rel="noreferrer"
          target="_blank"
          className="lg:self-start w-full lg:w-auto"
        >
          <Button variant="outline" className="w-full lg:w-auto">
            Play quizzes now
          </Button>
        </a>
      </div>
    </div>
  )
}
export default CodingQuizzesForKids
