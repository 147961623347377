// TODO: Add new geo coding-for-kids pages here
/* Ex:
 {
    title: 'Coding for kids in Nigeria',
    link: '/coding-for-kids/nigeria/',
  },
*/
const RELATED_RESOURCES = []

const RelatedCodingResources = () => {
  if (RELATED_RESOURCES.length === 0) return null

  return (
    <div className="text-grey container mx-auto mt-40 mb-20">
      <h2 className="text-2xl font-700 text-center lg:text-left">
        Resources related to Coding for kids
      </h2>
      <div className="flex flex-wrap gap-4 mt-4">
        {RELATED_RESOURCES.map((r) => (
          <a
            key={r.title}
            className="inline-block bg-grey-300 rounded-full px-5 py-2"
            href={r.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {r.title}
          </a>
        ))}
      </div>
    </div>
  )
}
export default RelatedCodingResources
