import Button from '@components/atoms/button'
import { FastlyImage } from '@components/common/image'

type CodingCourseType = {
  title: string
  age: string
  link: string
  imageName: string
  imageAltText: string
}

const CODING_COURSES: CodingCourseType[] = [
  {
    title: 'App Development',
    age: '6-14',
    link: 'https://www.codingal.com/courses/app-development/',
    imageName: 'app-dev',
    imageAltText:
      'Online app development courses for kids to create stunning apps',
  },
  {
    title: 'Web Development',
    age: '12-18',
    link: 'https://www.codingal.com/courses/web-development/',
    imageName: 'web-dev',
    imageAltText:
      'Online web development courses for kids to create amazing websites',
  },
  {
    title: 'Game Development',
    age: '6-14',
    link: 'https://www.codingal.com/courses/game-development/',
    imageName: 'game-dev',
    imageAltText:
      'Online game development course for kids to start creating interesting games',
  },
  {
    title: 'Python for kids',
    age: '12-18',
    link: 'https://www.codingal.com/courses/python-for-kids/',
    imageName: 'python-for-kids',
    imageAltText:
      'Online python programming course for kids to start learning basics of programming, code interactive apps and games',
  },
  {
    title: 'Data Science for kids',
    age: '14-18',
    link: 'https://www.codingal.com/courses/data-science/',
    imageName: 'data-science',
    imageAltText:
      'Online data science course for kids to start using AI, ML and neural networks',
  },
  {
    title: 'Scratch Programming',
    age: '6-12',
    link: 'https://www.codingal.com/courses/scratch-programming/',
    imageName: 'scratch-programming',
    imageAltText:
      'Online Scratch programming for kids to get started with coding',
  },
  {
    title: 'Coding Prodigy',
    age: '6-18',
    link: 'https://www.codingal.com/courses/coding-prodigy/',
    imageName: 'coding-prodigy',
    imageAltText:
      'A perfect online coding course for kids who want to excel at coding and build complex games and applications.',
  },
  {
    title: 'Coding Grandmaster',
    age: '6-18',
    link: 'https://www.codingal.com/courses/coding-grandmaster/',
    imageName: 'coding-grandmaster',
    imageAltText:
      'Best online coding course for kids who want to master the art of coding and create a better future through code.',
  },
]

const IMAGE_PATH = '/images/coding-for-kids/coding-courses/'

const CodingCourses = () => {
  return (
    <div
      style={{ backgroundColor: '#F9F9F9' }}
      className="py-10 my-20"
      id="coding-courses"
    >
      <div className="container mx-auto lg:text-center px-5 text-grey leading-tight">
        <h2 className="text-2xl lg:text-4xl font-700">
          Coding courses for Grade 1-12
        </h2>
        <p className="mt-4 mb-8 text-xl">
          Choose from a wide range of online coding courses best suited for your
          child
        </p>
        <div className="flex flex-wrap gap-y-5 lg:gap-10 justify-center text-center">
          {CODING_COURSES.map((course) => (
            <div
              key={course.title}
              className="relative rounded-xl w-80 border border-grey-500"
            >
              <div className="relative w-80 h-40">
                <FastlyImage
                  src={`${IMAGE_PATH}${course?.imageName}.png`}
                  webpSrc={`${IMAGE_PATH}${course?.imageName}.webp`}
                  layout="fill"
                  alt={course?.imageAltText}
                />
              </div>
              <div className="my-4">
                <p className="font-700 text-lg mb-2">
                  {course.title} <br />
                  for Age {course.age}
                </p>
                <a href={course?.link} target="_blank" rel="noreferrer">
                  <Button>Explore now</Button>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default CodingCourses
